<template>
  <div>
    <div>
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <div class="btn-group ml-5">
            <vs-button
              size="small"
              color="success"
              type="border"
              @click="handleExport('excel')"
              >Excel</vs-button
            >
          </div>
        </template>
        <template slot="thead">
          <!-- <vs-th></vs-th> -->
          <vs-th
            v-for="(head, index) in this.table.heads"
            :key="index"
            :sort-key="head.field"
            >{{ head.title }}</vs-th
          >
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <!-- <vs-td> </vs-td> -->
            <vs-td
              v-for="(head, index) in table.heads"
              :key="index"
              :data="data[indextr][head.field]"
              >{{ data[indextr][head.field] }}</vs-td
            >
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
  </div>
</template>
<script>
// import { AgGridVue } from "ag-grid-vue";
// import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import moment from "moment";
export default {
  components: {
    // AgGridVue,
  },
  props: {
    baseUrl: {
      type: String,
    },
    startDate: {
      type: Date,
    },
    endDate: {
      type: Date,
    },
    docType: {
      type: String,
    },
    territoryIDs: {
      type: Array,
    },
    territoryNames: {
      type: Array,
    },
    draw: {
      type: Number,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        heads: [
          {
            field: "supplier_code",
            title: "Supplier Code",
          },
          {
            field: "document_type",
            title: "Document Type",
          },
          {
            field: "vendor_name",
            title: "Supplier Name",
          },
          {
            field: "reference",
            title: "Invoice External",
          },
          {
            field: "invoice_date",
            title: "Invoice Date",
          },
          {
            field: "invoice_value",
            title: "Invoice Value",
          },
          {
            field: "barcode",
            title: "Barcode",
          },
          {
            field: "status",
            title: "Status",
          },
          {
            field: "user_name",
            title: "User email",
          },
          {
            field: "priority",
            title: "Priority",
          },
          {
            field: "scan_date",
            title: "Scan Date",
          },
          {
            field: "scan_date",
            title: "SLA Due Date",
          },
          {
            field: "aging_in_days",
            title: "Aging in Days",
          },
          {
            field: "reject_reason",
            title: "Reject Reason",
          },
          {
            field: "doc_no",
            title: "Doc No",
          },
          {
            field: "posting_date",
            title: "Posting Date",
          },
          // {
          //   field: "nomor_payment",
          //   title: "SAP FI Clearing Document",
          // },
          // {
          //   field: "tanggal_payment_confirm",
          //   title: "SAP FI CLRG Date",
          // },
        ],
      };
    },

    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      if (this.draw > 0) {
        this.$vs.loading();
        this.$http
          .get(this.baseUrl + "/table", {
            params: {
              length: this.table.length,
              page: this.table.page,
              search: this.table.search,
              order: this.table.order,
              sort: this.table.sort,
              // status: this.status,
              territory_ids: this.territoryIDs,
              start_date: this.startDate
                ? moment(this.startDate).format("YYYY-MM-DD")
                : null,
              end_date: this.endDate
                ? moment(this.endDate).format("YYYY-MM-DD")
                : null,
            },
          })
          .then((resp) => {
            this.tableDefaultState();
            if (resp.status == "success") {
              this.table.total = resp.data.record_total;
              this.table.totalPage = resp.data.page_total;
              this.table.totalSearch = resp.data.record_total_search;
              this.table.length = resp.data.record_total_per_page;
              this.table.data = resp.data.report ? resp.data.report : [];
              this.setStartEnd();
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format("DD MMM YYYY");
    },
    handleExport(file) {
      this.$vs.loading();
      var filetitle =
        "SUPPLIER_INVOICE_OCR_REPORT_" +  this.docType +    "_" +
        moment(this.startDate).format("YYYY-MM-DD") +
        "_-_" +
        moment(this.endDate).format("YYYY-MM-DD");
      this.$http
        .get(this.baseUrl + "/export", {
          params: {
            start_date: moment(this.startDate).format("YYYY-MM-DD"),
            end_date: moment(this.endDate).format("YYYY-MM-DD"),
            file: file,
            title: filetitle,
          },
        })
        .then((resp) => {
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            if (file == "excel") {
              fileLink.setAttribute("download", filetitle + ".csv");
            } else {
              fileLink.setAttribute("download", filetitle + ".pdf");
            }
            document.body.appendChild(fileLink);
            fileLink.click();

          }
          this.$vs.loading.close();
        });
    },
  },
  watch: {
    detail() {
      this.getData();
    },
    startDate() {
      this.getData();
    },
    endDate() {
      this.getData();
    },
    docType() {
      this.getData();
    },
    draw(val) {
      if (val > 0) {
        this.getData();
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
